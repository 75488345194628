.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; // 修改这一行
  margin-top: 10px;
  padding: 25px 10px 300px;
  background-color: #f4f4f9;
}

.card {
  width: calc(50% - 10px); // 减去容器内边距的一半以保持对齐
  margin-bottom: 30px;
  margin-right: 10px; // 用于在每个卡片之间设置间距
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  cursor: pointer;
  text-decoration: none;

  &:nth-child(2n) {
    margin-right: 0; // 每行的最后一个卡片不需要右边距
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.35);
  }

  .card-image {
    width: 100%;
    height: 200px;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.9;
    }
  }

  .card-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 36rpx;
    font-weight: bold;
    text-align: center;
    color: #333;
    line-height: 1.5;
  }
}

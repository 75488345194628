.phonetic-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);

  .back-button {
    position: absolute;
    top: 10px;
    left: 5px;
    z-index: 100;
    padding: 6px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }

  .phonetic-info {
    flex: 1;
    width: 100%;
    max-width: 600px;
    overflow-y: auto;
    background: white;

    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);

    .info-container {
      padding: 0 0 40px;
      display: flex;
      flex-direction: column;
      align-items: start;

      .video_jq {
        font-size: 16px;
        padding: 8px 20px;
        font-weight: bold;
        background: linear-gradient(135deg, #ff7e5f, #feb47b);
        color: white;
        border-radius: 20px;
        margin-left: 10px;
        margin-top: 10px;
        text-decoration: none;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition:
          transform 0.2s ease,
          box-shadow 0.2s ease;

        &:hover {
          transform: scale(1.05);
          box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
        }
      }

      .notice {
        padding: 10px 10px;
        margin-top: 10px;
        margin-left: 10px;
        background: #fef9e7;
        border-left: 5px solid #ffc107;
        border-radius: 10px;
        color: #333;
        font-weight: bold;
        font-size: 16px;
        line-height: 1.5;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
      }

      .desc {
        padding: 0px 20px;
        color: #444;
        line-height: 1.8;
        font-size: 16px;
        letter-spacing: 1px;
        margin-top: 0px;
        border-radius: 10px;
        background: #f9f9f9;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
      }

      .disc {
        margin-top: 50px;
        padding: 20px;
        color: #444;
        line-height: 1.8;
        font-size: 18px;
        letter-spacing: 1.2px;
        font-weight: bold;
        background: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
      }
    }
  }

  .table {
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
    padding: 10px;
    background: white;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);

    .cell {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      border-radius: 10px;
      margin: 5px;
      background: linear-gradient(135deg, #6a11cb, #2575fc);
      color: white;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition:
        transform 0.2s ease,
        box-shadow 0.2s ease;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

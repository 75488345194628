.title {
  margin-top: 15px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.list {
  list-style: none;
  padding: 0px 5px 300px;
  display: flex;
  flex-direction: column;
}

.item {
  margin-bottom: 5px;
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
  text-decoration: none;
}

.index {
  margin-right: 10px;
  color: gray;
}

.name {
  color: black;
}

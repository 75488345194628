.loading {
  z-index: 2001;
  position: absolute;
  left: 50%;
  top: 50%;
  background: white;
  border-radius: 5px;
  padding: 20px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  .img {
    width: 100px;
    height: 100px;
  }

  .text {
    width: 100px;
    text-align: center;
    margin-top: 5px;
    color: #888; /* 你可以根据实际需要修改颜色 */
    font-size: 16px; /* 根据需要调整字体大小 */
  }
}

.jingqi-explanation-container {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  .back-button {
    position: absolute;
    top: 10px;
    left: 5px;
    z-index: 100;
    padding: 6px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }

  .video-container {
    flex: 1;
    position: relative;
    background: #000;
    overflow: hidden;

    .video-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      :global {
        .video-react {
          width: 100% !important;
          height: 100% !important;

          video {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

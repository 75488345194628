$primary-color: #08cbbf;

.choice {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;

  &::-webkit-scrollbar {
    display: none;
  }

  .subject-type {
    color: white;
    background-color: #999999;
    border-radius: 20px;
    padding: 3px 16px;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .question_en {
    font-size: 40px;
    color: $primary-color;
    text-align: center;
    letter-spacing: 1px;
    margin-top: 20px;
    line-height: 1.8;
  }

  .question_zh {
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 1.8;
    min-height: 40px;
    margin: 20px 10px 10px;
  }

  .audio-item {
    margin-left: 20px;
    .audio-img {
      width: 48px;
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 15px;
    padding: 0;

    li {
      cursor: pointer;
      width: 100%;
      background-color: $primary-color;
      min-height: 56px;
      line-height: 1.4;
      border-radius: 10px;
      font-size: 24px;
      color: #fff;
      padding: 3px 30px;
      box-sizing: border-box;
      letter-spacing: 1px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: start;
      position: relative;

      .option {
        display: flex;
        align-items: start;
        justify-content: start;

        .tag {
          margin-right: 5px;
        }
      }

      &.right_choose {
        background-color: #f1ffb8;
        color: #298409;
      }

      &.wrong_choose {
        background-color: #ffe2ce;
        color: #ff0000;
      }

      &.en {
        font-size: 24px;
        letter-spacing: 1px;
      }

      & + li {
        margin-top: 26px;
      }

      .judge {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;

        .img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  .parsing {
    display: flex;
    flex-direction: column;
    align-items: start;
    overflow-x: scroll;
    margin-top: 20px;
    margin-left: 25px;

    .tag {
      font-size: 14px;
      color: #999;
      margin-right: 5px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .explanation {
      color: #000;
      overflow-x: scroll;
      flex: 1;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: 1px;
    }

    .explanation-image {
      flex: 1;
      overflow-x: auto;
    }
  }

  .next {
    position: fixed;
    bottom: 80px;
    background-color: $primary-color;
    left: 50%;
    transform: translate(-50%);
    border-radius: 8px;
    color: #fff;
    text-align: center;
    font-size: 24px;
    padding: 12px 36px;
  }

  .count-down {
    position: fixed;
    right: 0px;
    top: 20px;
    width: 82px;
    height: 30px;
    background: linear-gradient(88deg, #f4cf6b, #ff9b0b);
    border-radius: 30px 0 0 30px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 30px;
  }
}

$primary-color: #08cbbf;
/* 行为相关颜色 */
$uni-color-primary: #007bff;
$uni-color-success: #4cd964;
$uni-color-warning: #f0ad4e;
$uni-color-error: #dd524d;

/* 文字基本颜色 */
$color_text_first: #242424;
$color_text_second: #666666;
$color_text_third: #aaaaaa;

.word-paper-wrap {
  padding-bottom: 200px;

  .close {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    z-index: 10200;
    font-size: 20px;
    border-radius: 50%;
    background: $primary-color;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.word-paper {
  width: 750rpx;
  height: 100vh;
  background: #eeeeee;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .result {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    .success {
      height: 100px;
      margin-top: 0px;
    }

    .fail {
      height: 100px;
      margin-top: 0px;
    }

    .point {
      font-size: 40px;
      color: $uni-color-warning;
      font-weight: bold;
    }

    .point-fail {
      margin: 10px 0px;
      color: #f15b00;
    }

    .point-text {
      margin-top: 10px;
      color: $color_text_second;
      font-size: 18px;
    }

    .question-info {
      color: $color_text_second;
      margin-top: 10px;

      .tag {
        display: inline-block;
        font-size: 12px;
      }

      .right {
        margin: 0px 10px;
        font-size: 20px;
        color: $primary-color;
      }

      .wrong {
        color: #f15b00;
        font-size: 20px;
        margin: 0px 10px;
      }
    }

    .paper-title {
      margin-top: 20px;
      color: #f3f3f3;
      font-size: 18px;
      letter-spacing: 1px;
      line-height: 1.2;
      display: flex;
      flex-direction: column;
      width: 100%;

      .tag {
        display: inline-block;
        min-width: 70px;
        font-size: 12px;
      }
    }
  }

  .main {
    margin-top: 5px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: white;
    border-radius: 12px 12px 0 0;

    // overflow-y: auto;

    .module-contain {
      // margin-bottom: 30px;
      border-radius: 12px 12px 0 0;

      .title {
        // border: 2px solid $primary-color;
        // border-radius: 12px 12px 0 0;
        background: white;
        height: 10px;
        line-height: 35px;
        font-size: 16px;
        color: $uni-color-warning;
        // font-weight: 600;
        box-sizing: border-box;

        .title-icon {
          display: inline-block;
          width: 3px;
          height: 12px;
          // border-radius: 2px;
          background: $uni-color-warning;
          position: relative;
          margin: 0 5px 0 15px;

          &::after {
            content: '';
            display: block;
            border-width: 2px;
            border-style: solid;
            border-color: #fff transparent transparent transparent;
            position: absolute;
            bottom: -3px;
            right: 3px;
          }
        }
      }

      .translate,
      .oral-evaluation,
      .spell {
        background: white;
        // margin-bottom: 12px;
        color: #333;

        ul {
          // background: red;
          // border: 2px solid #02bfc4;
          overflow: hidden;
          // border-radius: 0 0 12px 12px;
          padding: 0px 0 2px;

          li {
            min-height: 30px;
            line-height: 24px;
            display: flex;
            flex-direction: column;
            font-size: 12px;
            cursor: pointer;
            padding: 10px;
            position: relative;

            &:nth-child(2n) {
              background: #d4f9fa;
            }

            .judge {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 20px;

              .img {
                width: 32px;
                height: 32px;
              }
            }

            .your-answer {
              color: #f15b00;
              font-size: 20px;
              margin: 5px 0 0 10px;

              .tag {
                color: #333333;
                font-size: 14px;
                margin-right: 5px;
              }

              .option {
                margin-right: 3px;
              }
            }

            .question {
              width: 450rpx;
              padding: 0 15px 10px 15px;
              color: black;

              span {
                min-width: 22px;
                color: #333333;
              }
            }

            .answer {
              width: 300px;
              padding: 0 0px;
              color: #31b327;
              font-size: 20px;
            }
          }
        }
      }

      .oral-evaluation {
        .answer {
          img {
            width: 18.75px;
            height: 16.5px;
          }
        }

        .your-answer {
          img {
            margin-right: 10px;
            width: 18.75px;
            height: 16.5px;
          }
        }
      }
    }
  }
}

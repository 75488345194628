$primary-color: #08cbbf;

.exam-wrap {
  .exam-main {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2001;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .contain {
      width: 100%;
      flex: 1;
      background: #fff;
      box-sizing: border-box;
      position: relative;
      overflow-y: scroll;
    }

    .tip {
      color: #999999;
      font-size: 20rpx;
      background-color: white;
      text-align: center;
      position: fixed;
      bottom: 20px;
      left: 0px;
      right: 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-color: transparent;

      .index {
        margin-right: 10px;
      }

      .number {
        margin-left: 2px;
      }

      .right {
        color: $primary-color;
        margin-right: 3px;
      }

      .wrong {
        color: #dd524d;
        margin-right: 10px;
      }

      .time {
        margin-right: 5px;
      }
    }
  }
}

.grid-layout {
  display: grid;
  overflow-x: auto;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: repeat(8, 1fr);
  width: 100%;
  background: #02a1f9;
  border-radius: 12px 12px 0 0;
  padding: 4px;
  gap: 2px;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 26px;
  min-width: 50px;
  font-size: 14px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  color: white;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;

  &.active {
    background: white;
    color: #02a1f9;
    font-weight: 600;
    border: none;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }

  &:empty {
    background: transparent;
    border: none;
    pointer-events: none;
  }
}

.words-container {
  padding: 15px 15px 10px;
  display: flex;
  flex-direction: column;
  gap: 15px; /* 单词项之间的间距 */
}

.word-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 20px;
  background: linear-gradient(135deg, #f9f9f9, #ffffff);
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;

  .symbol {
    flex: 1;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    letter-spacing: 1px;

    .special-color {
      color: #ff5722; /* 高亮部分的颜色 */
      font-weight: bold;
    }
  }

  .phonogram {
    flex: 1;
    font-size: 18px;
    color: #666;
    margin-left: 10px;
    font-style: italic;
  }

  .word-desc {
    flex: 3;
    font-size: 16px;
    color: #444;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 1.5;
  }

  /* 音频播放按钮的样式 */
  .audio-player {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }
}

/* GlobalDialog.scss */
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;

  @media (max-width: 768px) {
    width: 70%;
  }
}

.modal-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.5em;
  text-align: center;
}

.modal-close-button {
  background: #ff4d4f;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 20px;
  display: block;
  width: 100%;
  text-align: center;
  transition: background 0.3s ease;

  &:hover {
    background: #ff7875;
  }
}

.question-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  line-height: 1.4;
}

.subject-type {
  color: white;
  background-color: #999999;
  border-radius: 20px;
  padding: 4px 8px;
  font-size: 14px;
  margin-bottom: 5px;
}

.subject {
  color: #000;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;

  .subject-left {
    display: flex;
    line-height: 24px;

    .filling {
      display: inline-block;
      width: 30px;
      border-bottom: 1px solid #727676;
      text-align: center;
    }

    > span {
      display: inline-block;
      color: #999;
      font-size: 14px;
      margin: 0px 3px;
    }

    blank-input {
      position: relative;
      top: 5px;
      display: inline-block;
      width: 100px;
      border-bottom: 1px solid #727676;
      margin: 0 2px;
    }
  }
}

.audio-item {
  margin-left: 10px;
  .audio-img {
    width: 24px;
  }
}

.subject-options {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  padding-left: 10px;
  font-size: 16px;

  .option {
    display: flex;
    align-items: start;
    justify-content: start;
    margin-right: 18px;
    box-sizing: border-box;

    span {
      color: #5a5e5e;

      &:nth-child(1) {
        display: inline-block;
        color: #999;
        margin-right: 5px;
      }

      &:nth-child(2) {
        max-width: 100%;
        color: #000;
        white-space: nowrap;
      }
    }
  }
}

.answer-parse-view {
  margin-left: 10px;
  position: relative;
  width: calc(100% - 30px);

  .answer-parse {
    color: #08cbbf;
    font-size: 14px;
    cursor: pointer;
  }

  .content-of-the-answer {
    margin-top: 5px;
    position: relative;
    width: 100%;

    .answer {
      display: flex;
      align-items: center;

      > span {
        display: inline-block;
        font-size: 12px;
        color: #999;
        margin-right: 5px;
      }

      view {
        color: #000;
      }
    }

    .parsing {
      margin-top: 10px;
      display: flex;
      align-items: start;
      overflow-x: scroll;

      .tag {
        font-size: 14px;
        color: #999;
        margin-right: 5px;
        padding-top: 2px;
      }

      .explanation {
        font-size: 16px;
        line-height: 1.4;
        color: #000;
        overflow-x: scroll;
        flex: 1;
      }

      .explanation-image {
        flex: 1;
        overflow-x: auto;
      }
    }
  }

  .change-enter-active {
    transition: all 0.6s ease;
  }

  .change-leave-active {
    transition: all 0.2s ease;
  }

  .change-enter {
    max-height: 0;
    opacity: 0;
  }

  .change-enter-to {
    max-height: 300px;
    opacity: 1;
  }

  .change-leave {
    max-height: 300px;
  }

  .change-leave-to {
    opacity: 0;
    max-height: 0;
  }

  .show {
    opacity: 1;
    max-height: 300px;
  }
}

.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  font-family: Arial, sans-serif;

  .form-content {
    width: 80%;
    max-width: 400px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .logo {
      margin: 20px 0 10px;
      width: 120px;
      height: 100px;
      margin-bottom: 20px;
      object-fit: fill;
    }

    .input-group {
      margin-bottom: 25px;
      width: 100%;

      .input-field {
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        outline: none;
        width: 100%;
      }

      .captcha-btn {
        padding: 12px;
        font-size: 16px;
        color: #ffffff;
        background-color: #007bff;
        padding: 15px 20px;
        border: none;
        border-radius: 100px;
        cursor: pointer;
        outline: none;
        text-align: center;
        width: 100%;

        &:disabled {
          background-color: #cccccc;
          cursor: not-allowed;
        }
      }
    }

    .tip {
      font-size: 14px;
      color: black;
      text-align: center;
      line-height: 1.6;
    }
  }

  .result {
    text-align: center;
    padding: 50px 0 300px;

    .img {
      width: 100px;
      height: 80px;
      margin-bottom: 20px;
    }

    .congrats {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .account {
      font-size: 20px;
      margin-top: 10px;
      color: #007bff;
      font-weight: bold;
      line-height: 20px;
    }

    .close-btn {
      padding: 10px 20px;
      font-size: 16px;
      color: #ffffff;
      background-color: #ff0000;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      outline: none;
      margin-top: 20px;

      &:hover {
        background-color: #cc0000;
      }
    }
  }

  .u-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .result {
      background: white;
      border-radius: 16px;
      padding: 20px;
      text-align: center;
    }
  }
}

.unit-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100% - 50px);
  padding-bottom: 50px;

  .title {
    margin-top: 15px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }

  .video-container {
    width: 100%;
    position: relative;
    background-color: black;
  }

  .content {
    flex: 1;
    position: relative;

    .page-main-scroll {
      height: 100%;
    }

    .context-question {
      padding-bottom: 200px;
      display: flex;
      flex-direction: column;
    }
  }

  .question {
    width: 100%;
    background: #fff;
    border-radius: 0px;
    padding: 10px 4px;
    position: relative;
    border-bottom: 1px solid #d6d6d6;
    transition: all 1s;
    line-height: 1.2;

    &:hover {
      background: #f6f7f7;
    }
  }

  .practice-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: #08cbbf;
    color: #fff;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
  }
}

$primary-color: #4caf50;
$background-color: #f5f5f5;
$input-background-color: #ffffff;
$button-hover-color: #45a049;
$button-active-color: #388e3c;
$border-color: #d1d1d1;

.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  min-height: 100vh;
  background-color: $background-color;
  font-family: Arial, sans-serif;
  padding: 20px;

  .logo {
    margin-top: 40px;
    width: 100px;
  }

  h1 {
    color: $primary-color;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }

  .form-group {
    margin-bottom: 15px;
    width: 100%;
    max-width: 400px;

    label {
      display: block;
      font-weight: bold;
      margin-bottom: 5px;
    }

    input {
      width: 100%;
      padding: 10px;
      border: 1px solid $border-color;
      border-radius: 8px;
      font-size: 16px;
      background-color: $input-background-color;
      box-sizing: border-box;
      transition: border-color 0.3s ease;

      &:focus {
        border-color: $primary-color;
        outline: none;
      }
    }

    .register-button {
      width: 100%;
      padding: 10px;
      border: 1px solid $border-color;
      border-radius: 8px;
      font-size: 16px;
      color: #fff;
      background-color: $primary-color;
      cursor: pointer;
      transition: background-color 0.3s ease;
      margin-top: 10px;

      &:hover {
        background-color: $button-hover-color;
      }

      &:active {
        background-color: $button-active-color;
      }
    }
  }

  .verification-container {
    display: flex;
    align-items: center;

    input {
      flex: 1;
      margin-right: 0px;
      border-radius: 8px 0 0 8px;
    }

    .verification-button {
      flex-shrink: 0;
      padding: 10px;
      border: 1px solid $border-color;
      border-radius: 0 8px 8px 0;
      font-size: 16px;
      color: #fff;
      background-color: $primary-color;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: $button-hover-color;
      }

      &:active {
        background-color: $button-active-color;
      }
    }

    .disable {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }
}

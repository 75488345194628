.login-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  text-align: center;

  .logo {
    margin: 50px 0 0;
    width: 100px;
    height: 80px;
    object-fit: contain;
  }

  .form-title {
    font-size: 14px;
    margin-bottom: 20px;
    color: #999;
  }

  .form-content {
    margin-bottom: 30px;
    width: 90%;

    .input-group {
      display: flex;
      flex-direction: row;

      .input-field {
        border: none;
        border-bottom: 1px solid #e5e5e5;
        font-size: 16px;
        padding: 10px;
        outline: none;
        text-align: left;
        flex: 1;
      }
    }

    .login-btn {
      margin-top: 20px;
      border: none;
      background-color: #007aff; // 使用适当的颜色值替代 #007aff
      color: #fff;
      padding: 10px;
      font-size: 16px;
      border-radius: 3px;
      cursor: pointer;
      width: 100%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    }
  }

  .tip {
    font-size: 12px;
    text-align: center;
    color: #999;

    .contract {
      color: #007aff; // 使用适当的颜色值替代 #007aff
      font-size: 20px;
      margin: 0 2px;
      font-weight: bold;
    }
  }

  .qr-code-container {
    width: 750px; // 使用 px 单位，因为 React 常用 px
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    .content {
      position: fixed;
      opacity: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;

      .qr-code {
        width: 220px;
        border-radius: 10px;
        margin-top: -10px;
      }

      .tip {
        margin-top: 10px;
        font-size: 14px;
        color: white;
        font-weight: bold;
      }
    }
  }
}

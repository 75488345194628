$primary-color: #08cbbf;

.catalog-button {
  z-index: 1000;
  position: fixed;
  bottom: 80px;
  left: 10px;
  width: 60px;
  height: 60px;
  background-color: $primary-color;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  cursor: pointer; /* 添加指针光标 */
}

.catalog-button:hover {
  transform: scale(1.1);
}

.catalog-text {
  /* 如果需要进一步调整文字样式，可以在这里添加样式 */
}

.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 纵横比 */
  overflow: hidden;

  .responsive-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.t-video-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  .responsive-video {
    width: 100%;
    video {
      object-fit: contain;
    }
  }
}

.c-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: black;
  min-height: 180px;

  .downloadBtn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    background-color: #08cbbf; /* 按钮背景色 */
    color: #ffffff; /* 文字颜色 */
    border: none;
    cursor: pointer;
    border-radius: 5px; /* 圆角 */
    transition: background-color 0.3s ease; /* 过渡效果 */
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .loading-circle {
    position: relative;
    width: 50px;
    height: 50px;
    border: 3px solid #ccc;
    border-top: 3px solid #08cbbf;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading-percent {
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    color: white;
    margin-top: -25px;
  }
  .loading-text {
    margin-top: 10px;
    font-size: 16px;
    color: #aaa;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .video-player {
    width: 100%;
    height: 100%;
  }
}

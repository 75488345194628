.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  min-height: 100vh;
  background-color: #f0f8ff;
  text-align: center;
  font-family: Arial, sans-serif;

  .logo {
    margin-top: 40px;
    width: 100px;
  }

  h1 {
    color: #4caf50;
    margin-bottom: 20px;
  }

  .info {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left; /* 修改为左对齐 */
    width: 80%;
    max-width: 400px;

    .info-item {
      margin-bottom: 15px;

      label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
      }

      input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        background-color: #f9f9f9;
        cursor: text;
        box-sizing: border-box;
      }
    }
  }

  .reminder {
    margin-top: 20px;
    font-weight: bold;
    color: #f00;
    font-size: 20px;
    line-height: 1.4;
    p {
      margin: 10px 0;
    }
  }
}
